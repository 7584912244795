/* You can add global styles to this file, and also import other style files */
// Angular Material
// @use "../node_modules/@angular/material" as mat;

// // TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
// //  The following line adds:
// //    1. Default typography styles for all components
// //    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
// //  If you specify typography styles for the components you use elsewhere, you should delete this line.
// //  If you don't need the default component typographies but still want the hierarchy styles,
// //  you can delete this line and instead use:
// //    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
// @include mat.all-component-typographies();
// @include mat.core();

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
// @import "node_modules/bootstrap/scss/bootstrap";
// Required
@import "../node_modules/bootstrap/scss/bootstrap";
@import './styles-override.scss';
// @import "../node_modules/intl-tel-input/build/css/intlTelInput.css";

// .iti__flag {background-image: url("../node_modules/intl-tel-input/build/img/flags.png");}

// @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
//   .iti__flag {background-image: url("../node_modules/intl-tel-input/build/img/flags@2x.png");}
// }

.btn-primary {
    background: $primary;
    border-color: $primary;
}

.btn-secondary {
    background: white;
    border-color: $primary;
    color: black;
}

html, body { height: 100%; }
body { margin: 0; font-family: "Mulish", "Helvetica Neue", sans-serif; }
